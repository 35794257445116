// @flow
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config } from 'react-awesome-styled-grid'

export const BadgeTextInline = styled.div`
    font-family: "Good Sans Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.3px;
    color: ${colors.white};
    display: inline-block;
    width: fit-content;
    background-color: ${colors.midnightBlue70};
    border-radius: 100px;
    border: 0.5px solid ${colors.white};
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin-top: 16px;
    margin-bottom: 16px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      font-size: 12px;
    `}
    ${(props: any): string => config(props).media.lg`
      font-size: 14px;
    `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const BadgeTextFloat = styled.div`
    font-family: "Good Sans Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.3px;
    color: ${colors.white};
    position: absolute;
    background-color: ${colors.midnightBlue70};
    border-radius: 100px;
    border: 0.5px solid ${colors.white};
    top: 13px;
    left: 13px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      font-size: 12px;
    `}
    ${(props: any): string => config(props).media.lg`
      font-size: 14px;
    `}
    ${(props: any): string => config(props).media.xl`
  `}
`

export default BadgeTextFloat
