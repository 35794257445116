// @flow
import * as React from 'react'
import type { HorizontalMediumTrackRowPropsType } from '../../models/Discover'
import Link from 'next/link'
import type { TrackPropsType } from '../../models/Track'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { useRouter } from 'next/router'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'
import TrackTags from '@components/Tag/TrackTags'
import { FadeInImage } from '@components/shared/FadeInImage'
import { getComingSoonText } from '../../models/Track'
import BadgeTextFloat from '@components/BadgeText'
function HorizontalMediumTrackRow (props: HorizontalMediumTrackRowPropsType): React.Node {
  const router = useRouter()
  return (
    <RecRowContainer>
      <HeaderSeeAll title={props.title} cta={props.cta} description={props.description}/>
      <RowContainer>
        {props.items.map((item: TrackPropsType, index: number): React.Node => {
          const comingSoon = getComingSoonText(item.publishedAt)
          return (
            <CardItem key={`hositonal-large-track-row-${item.id}-${index}`}>
            <ImageContainer onClick={(): void => router.push(item.slug)}>
              {comingSoon && <BadgeTextFloat>{comingSoon}</BadgeTextFloat>}
              <FadeInImage src={item.images.img500} fill={true} width={500} height={500}/>
            </ImageContainer>
              <HeaderTitleText>
                <Link href={item.slug} passHref={true}>
                  {item.title}
                </Link>
              </HeaderTitleText>
            {item.series?.[0] && (<SeriesInfo>{item.series?.[0]?.episodeInfo} <Link href={item.series[0].slug} passHref={true}>{item.series[0].title}</Link></SeriesInfo>)}
              <TagsContainerMobile>
                <TrackTags tags={item.thematicTags.length > 0 ? item.thematicTags : item.tags } maxLines={3}/>
              </TagsContainerMobile>
              <TagsContainerTablet>
                <TrackTags tags={item.thematicTags.length > 0 ? item.thematicTags : item.tags} maxLines={2} clickable={true}/>
              </TagsContainerTablet>
          </CardItem>
          )
        })}
      </RowContainer>
    </RecRowContainer>
  )
}

export default HorizontalMediumTrackRow

const TagsContainerMobile = styled.div`
    display: inline;
    margin-top: 8px;
    ${(props: any): string => config(props).media.md`
    display: none;
  `}
`

const TagsContainerTablet = styled.div`
    display: none;
    margin-top: 8px;
  ${(props: any): string => config(props).media.md`
    display: inline;
  `}
`

const RecRowContainer = styled.div`
  width: 100%;
`

const SeriesInfo = styled.div`
    margin-top: 8px;
    color: ${colors.white};
    font-family: "Good Sans Regular";
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    letter-spacing: -0.3px;
    font-size: 13px;
    ${(props: any): string => config(props).media.md`
          font-size: 16px;
    `}

    a {
        color: ${colors.tintOrange};
        text-decoration: underline;
    }
`

const RowContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.md`
      gap: 16px;
  `}
    ${(props: any): string => config(props).media.lg`
      gap: 20px;
    `}
`

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 162px;
    ${(props: any): string => config(props).media.md`
      width: 182px;
    `}
    ${(props: any): string => config(props).media.lg`
      width: 200px;
  `}
    ${(props: any): string => config(props).media.xl`
      width: 226px;
    `}
`

const ImageContainer = styled.div`
    width: 162px;
    height: 162px;
    position: relative;
    z-index: 0;
    img {
        border-radius: 8px;
    }

    ${(props: any): string => config(props).media.md`
      width: 182px;
      height: 182px;
    `}
    ${(props: any): string => config(props).media.lg`
      width: 200px;
      height: 200px;
  `}
    ${(props: any): string => config(props).media.xl`
      width: 226px;
      height: 226px;
    `}
`
const HeaderTitleText = styled.div`
    a {
        margin-top: 12px;
        color: ${colors.white};
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: -0.3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        ${(props: any): string => config(props).media.md`
          margin-top: 16px;
        `}
        ${(props: any): string => config(props).media.md`
          margin-top: 16px;
        `}
        ${(props: any): string => config(props).media.lg`
          font-size: 18px;
          margin-top: 20px;
          letter-spacing: 0px;
        `}
    }
`
